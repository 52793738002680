import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import { Link } from "gatsby"
import styled from 'styled-components'
import BgImage from '../images/brand-list.jpg'
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton";
import { StaticImage } from "gatsby-plugin-image"
import BreakpointDown from "../components/Media/BreakpointDown";


const SectionBgAfter = styled(Section)`
 &:after{
   right:0;
   left:0;
   background-size:cover;
 }
 .container{
   z-index:1;
 }
`
const PageTitle = styled.h1`
 margin-bottom:0;
 text-transform: capitalize;
 text-align:center;
`
const BrandLogo = styled.div`
 position:relative;
 margin-left:auto;
 margin-right:auto;
 max-width:200px;
 margin-top:10px;
`
const PageTitleWrapper = styled.div`
text-align:center;
font-size:16px;
img{ margin-top:10px;}
p{ margin-bottom:0;}
`
const SectionTitle = styled.h2`
 margin-bottom:0;
`
const BrandWrapper = styled.div`
  &+&{
    margin-top:100px;
    ${BreakpointDown.md`
     margin-top:40px;
    `}
  }
`
// blog Card 

const BrandRow = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-left:-20px;
 margin-right:-20px;
 ${BreakpointDown.lg`
  margin-left:-15px;
  margin-right:-15px;
 `}

`
const BrandColumn = styled.div`
 flex:0 0 100%;
 width:100%;
 padding-left:20px;
 padding-right:20px;
 margin-top:40px;
 ${BreakpointDown.lg`
   padding-left:15px;
 padding-right:15px;
 `}
 ${BreakpointDown.md`
  margin-top:30px;
 `}
`

const Card = styled.div`
  padding: 70px 62px;
  background:#fff;
  display:flex;
  height:100%;
  align-items:center;
  box-shadow: 0px 20px 45px rgb(0, 0, 0, 0.20);
  @media(max-width:1440px){
    padding: 15px;
  }
  ${BreakpointDown.md`
   text-align:center;
   flex-wrap:wrap; 
 `}
  .btn{
    margin-top:0px;
  }
`
const CardTitle = styled(Link)`
 margin-bottom:6px;
`
const CardImg = styled.div`
  position: relative;
  max-width:200px;
  width:100%;
  margin-top:26px;
  ${BreakpointDown.md`
     max-width:200px;
     margin-top:0px;
     margin-left:auto;
     margin-right:auto; 
  `}
  .gatsby-image-wrapper{ 
    width:100%;
  }
`
const BrnadDesciption = styled.div`
  display:flex;
  flex-direction:column;
  padding:30px 30px 30px 130px;
${BreakpointDown.lg`
   padding:15px;
`}
${BreakpointDown.md`
   padding:0;
   margin-top:15px;
`}
p{ font-size:16px;}
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3; 
`
function BrandListPage() {
  return (
    <Layout pageName="brand-list">
      <SEO
        title="CYBERPOWER: Power Management Solutions for Businesses"
        description="WYN Technologies offers CYBERPOWER professional-grade power protection equipment and software for a wide variety of applications and environments for businesses across states."/>
      <SectionBgAfter pt="156px" pb="100px" xpt="90px" xpb="60px" mpt="60px" mpb="40px" bgAfter={`url(${BgImage})`}>
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link> <Link to="/#">cyberpower</Link>
          </BreadCrumb>
          <PageTitle>CYBERPOWER: Power Management Solutions</PageTitle>
          <BrandLogo>
            <StaticImage
              src="../images/brand-1.png"
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Letast Article"
              placeholder="blurred"
            />
          </BrandLogo>
          <p><strong>CYBERPOWER</strong> provides professional-grade power protection equipment and software for a wide variety of applications and environments. These proven, trusted power management solutions meet the most critical requirements of corporate offices, retail groups, healthcare organizations, government facilities, and educational campuses. There are also CYBERPOWER solutions specially designed to meet the needs of small businesses, medium-sized businesses, and home offices. CYBERPOWER’s corporate vision to be your ultimate ally in power is clear, and is captured well by their slogan: Power Your Way – Solutions for Every Environment."</p>
        </div>
      </SectionBgAfter>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">Why CYBERPOWER?</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                <p>Founded in 1997, Cyber Power Systems (USA), Inc. designs and manufactures an impressive line of award-winning products, including uninterruptible power supply (UPS) systems, power distribution units (PDUs), surge protectors, mobile charging devices, connectivity devices, Fiber-to-the-Premise (FTTx) backup power solutions, as well as proprietary software applications for managing your power management system!</p>
                <p>Their corporate Core Values tell you a lot about why CYBERPOWER is a company you can feel good about partnering with, too:</p>
            <h3>BEYOND</h3>
                 <ul>
                  <li>
                    <strong>Curiosity – Ask, “Is better possible?”</strong>
                    </li>
                  <li>
                    <strong>Innovate – Create demand and answers.</strong>
                    </li>
                  <li>
                    <strong>Drive – Exhibit willingness, desire, and passion to move forward.</strong>
                    </li>
                  <li>
                    <strong>Commitment – Believe it, see it through, and surpass expectations.</strong>
                    </li>
                  </ul>
         <h3>OWN IT</h3>
                 <ul>
                  <li>
                    <strong>Accountability – Be responsible for the success of CyberPower.</strong>
                    </li>
                  <li>
                    <strong>Proactive – Don’t wait; take action.</strong>
                    </li>
                  <li>
                    <strong>Try – Don’t be afraid to fall forward.</strong>
                    </li>
                  <li>
                    <strong>Progress – Look for opportunities to learn and grow.</strong>
                    </li>
                    <li>
                    <strong>Pride – Take ownership of what you do and what you’ve accomplished.</strong>
                    </li>
                  </ul>
          <h3>BALANCE</h3>
                 <ul>
                  <li>
                    <strong>Adapt – Cope effectively and respectfully with change.</strong>
                    </li>
                  <li>
                    <strong>Engage – Be willing to listen and participate as a team member.</strong>
                    </li>
                  <li>
                    <strong>Commit – To the success of the company and the individual.</strong>
                    </li>
                  <li>
                    <strong>Prioritize – Between your work and your play.</strong>
                    </li>
                  </ul>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>

      {/* <Section pt="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8" pb="100px" xpb="60px">
        <div className="container">
          <BrandWrapper>
            <PageTitleWrapper>
              <SectionTitle className="h1">Products</SectionTitle>
              <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum been industry’s standard.</p>
            </PageTitleWrapper>
            <BrandRow>
             
                <BrandColumn>
                  <Card>
                    <CardImg>
                      <StaticImage
                        src="../images/phone-reciever.png"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <BrnadDesciption>
                      <CardTitle to="/brand-detail" className="h5">ZIP 43GExceptional Value in a Feature Rich IP Phone</CardTitle>
                      <p>The ZIP 47G combines a hi-resolution color display and dual Gigabit Ethernet ports in a feature rich IP phone ideal for busy executives and heavy phone users. Functions and contacts may be accessed quickly via 27 programmable soft keys.</p>
                      <Link to="/brand-detail"><PrimaryLinkButton text="View Full Detail" /></Link>
                    </BrnadDesciption>
                  </Card>
                </BrandColumn>
            
            </BrandRow>
          </BrandWrapper>
         <BrandWrapper>
            <PageTitleWrapper>
              <SectionTitle to="/brand-detail">IP Phone Systems</SectionTitle>
              <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum been industry’s standard.</p>
            </PageTitleWrapper>
            <BrandRow>
             
                <BrandColumn>
                  <Card>
                    <CardImg>
                      <StaticImage
                        src="../images/phone-reciever.png"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <BrnadDesciption>
                      <CardTitle to="/brand-detail" className="h5">ZIP 43GExceptional Value in a Feature Rich IP Phone</CardTitle>
                      <p>The ZIP 47G combines a hi-resolution color display and dual Gigabit Ethernet ports in a feature rich IP phone ideal for busy executives and heavy phone users. Functions and contacts may be accessed quickly via 27 programmable soft keys.</p>
                      <Link to="/brand-detail"><PrimaryLinkButton text="View Full Detail" /></Link>
                    </BrnadDesciption>
                  </Card>
                </BrandColumn>
            
            </BrandRow>
          </BrandWrapper>
        </div> 
      </Section>*/}
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px" bgColor="rgb(248, 248, 248)">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">A Few Examples of CYBERPOWER Products We Install & Configure at WYN Technologies</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                <h3>3-PHASE MODULAR UPS</h3>
                <p>CYBERPOWER's online double conversion, 3-Phase Modular UPS Systems bring flexibility and efficiency to 3-Phase power protection. The scalable modular design can be configured as a tower, side-by-side, or integrated within a rack.</p>
                 <ul>
                  <li>
                    <strong>Smart App Online</strong>
                    <p>Ideal for high-end data center systems which include servers, network equipment, and other sensitive electronic devices. CYBERPOWER Smart App Online 3-Phase Modular UPS Systems provide reliable, uninterruptible AC power achieved through double conversion. Comes with many advanced features, too.</p>
                  </li>
                  <li>
                    <strong>Battery Cabinets</strong>
                    <p>CYBERPOWER 3-Phase Modular UPS Battery Cabinets are designed to accommodate several battery modules and can be configured into tower or side-by-side formations, or easily mounted in a rack.</p>
                  </li>
                  <li>
                    <strong>Battery Modules</strong>
                    <p>CYBERPOWER 3-Phase Modular UPS Battery Modules contain ten 12V DC batteries. Two of these 120V DC modules fill one shelf of a battery cabinet, equaling one full 240V DC battery string.</p>
                  </li>
                  <li>
                    <strong>Power Modules</strong>
                    <p>CYBERPOWER 3-Phase Modular UPS Power Modules feature a high-density, scalable design providing 10kVA/10kW power in 2U height. This design helps to save space, and allows for easier capacity expansion when needed.</p>
                  </li>
                  <li>
                    <strong>Hardware</strong>
                    <p>CYBERPOWER 3-Phase Modular UPS Hardware replacement parts and accessories include circuit breakers, fans, parallel cables, and more.</p>
                  </li>
                  </ul>
                </MarkdownContent>
                <MarkdownContent>
                <h3>UPS SYSTEMS</h3>
                <p>Protect sensitive electronics and equipment during power surges and blackouts with an Uninterruptible Power Supply (UPS) system from CYBERPOWER’s extensive line including standby, line-interactive, and double-conversion models, with backup capacities ranging from 350 VA to 10,000 VA. Key features include sine wave output, energy-saving GreenPower UPS™ design, data-line protection, and power management software.</p>
                 <ul>
                  <li>
                    <strong>Smart App Online</strong>
                    <p>CYBERPOWER Smart App Online rack/tower UPS models, with double-conversion topology, provide sine wave output to mission-critical applications and equipment requiring seamless power correction. These units offer generator compatibility and deliver clean AC power with zero transfer time.</p>
                  </li>
                  <li>
                    <strong>New Smart App Sinewave</strong>
                    <p>CYBERPOWER’s customer-inspired UPS series is the next generation of professional-grade UPS systems for corporate applications, and includes line-interactive topology, surge protection, battery backup, and sine wave output to eliminate power gaps that could cause unexpected shutdowns.</p>
                  </li>
                  <li>
                    <strong>Smart App Sinewave</strong>
                    <p>Designed with line interactive topology, CYBERPOWER Smart App Sinewave mini-tower, rackmount, and rack/tower UPS models offer guaranteed power protection for corporate and departmental applications. They provide sine wave output, offer Active PFC power source compatibility, and correct minor power fluctuations without switching to battery, thereby extending battery life.</p>
                  </li>
                  <li>
                    <strong>Smart App LCD</strong>
                    <p>Designed with line interactive topology and simulated sine wave output, CYBERPOWER Smart App LCD rackmount and rack/tower UPS models offer guaranteed power protection for departmental servers, networking hardware, and other equipment capable of using simulated sine wave output. These models provide cost-effective, feature-rich power protection for networking, servers, and telecom equipment. Includes a multifunction LCD panel, among other features.</p>
                  </li>
                  <li>
                    <strong>Hospital/Medical</strong>
                    <p>CYBERPOWER Medical Grade UPS systems have been designed to power and protect sensitive equipment in hospitals and healthcare facilities. Each medical UPS is UL 60601-1 tested to provide standby power in patient-care settings and comes with hospital-grade plugs and hospital-grade outlets, along with a built-in isolation transformer.</p>
                  </li>
                  <li>
                    <strong>PFC Sinewave</strong>
                    <p>PFC Sinewave UPS systems from CYBERPOWER, designed with line interactive topology, offer guaranteed power protection for individual work areas, home networking hardware and devices, entertainment electronics, department servers, networking and telecommunications hardware, and equipment (including high-end audio/visual equipment) requiring Active PFC power source compatibility. They provide sine wave output and correct minor power fluctuations without switching to battery, thereby extending battery life.</p>
                  </li>
                  <li>
                    <strong>Intelligent LCD</strong>
                    <p>Intelligent LCD UPS systems from CYBERPOWER are designed with line interactive or standby topology and offer guaranteed power protection for desktop computers, workstations, routers, modems, gaming consoles, and home theater equipment. They provide simulated sine wave battery backup power during outages, maintain steady voltage during brownouts, and offer surge protection against overvoltages and power spikes. Includes an LCD status panel, among other features.</p>
                  </li>
                  <li>
                    <strong>Ecologic</strong>
                    <p>CYBERPOWER Ecologic UPS systems, designed with standby topology, offer guaranteed power protection for individual home and small office computer systems. They provide simulated sine wave battery backup power during outages, maintain steady voltage during brownouts and blackouts, and offer surge protection against over voltages and power spikes. Also includes a multifunction LCD panel.</p>
                  </li>
                  <li>
                    <strong>AVR</strong>
                    <p>AVR UPS systems from CYBERPOWER, designed with line interactive topology, offer guaranteed power protection for desktop computers, workstations, personal electronics, and home networking/VoIP. They provide simulated sine wave battery backup power during outages, maintain steady voltage during brownouts, and offer surge protection against over voltages and power spikes. Features  Automatic Voltage Regulation (AVR) capabilities.</p>
                  </li>
                  <li>
                    <strong>Standby</strong>
                    <p>Standby UPS systems from CYBERPOWER, designed with standby topology, offer guaranteed power protection for desktop computers, workstations, personal electronics, and home networking/VoIP. They provide simulated sine wave battery backup power during outages, maintain steady voltage during brownouts and blackouts, and offer surge protection against overvoltages and power spikes.</p>
                  </li>
                  <li>
                    <strong>Hardware</strong>
                    <p>Optimize the operation of Smart App UPS systems with these CYBERPOWER Hardware products. Remote Management Cards (RMCARDs) provides remote management and configuration of a compatible UPS via a standard Web browser or network management system. The environmental sensor (ENVIROSENSOR) monitors the status of four connected devices, like door alarms and security switches. When used with select RMCARDs, the sensor also provides real-time temperature and humidity readings of your data center or other critical environments.</p>
                  </li>
                  <li>
                    <strong>Replacement Batteries</strong>
                    <p>CYBERPOWER UPS Replacement Battery Cartridges restore life to CyberPower UPS systems that have weak or completely depleted internal batteries. The pre-assembled cartridge installs easily in your CYBERPOWER UPS system, and includes reusable packaging for delivering expired batteries to a suitable recycling center.</p>
                  </li>
                  <li>
                    <strong>Extended Battery Modules</strong>
                    <p>Extended Battery Modules (EBMs) from CYBERPOWER, for select Smart App UPS models, ensure extended battery runtimes during power outages. Each rack/tower convertible EBM takes up 2U or 3U of rack space, depending upon the model, and can be installed in a tower form factor to match the UPS. The DC plug-and-play power connectors allow for daisy-chaining additional EBMs to a UPS system.</p>
                  </li>
                  </ul>
                </MarkdownContent>
                 <MarkdownContent>
                <h3>SOFTWARE</h3>
                <p>CYBERPOWER offers free power management software with compatible Uninterruptible Power Supply (UPS) systems to monitor and control your UPS. The PowerPanel® Personal and PowerPanel® Business software suites support operating systems including Windows, Linux, macOS, and various Virtual Infrastructure platforms. And the Kiosk Commander Software keeps kiosks and other unattended systems operating by power cycling the attached computer whenever it locks up or performs poorly. CYBERPOWER also offers utilities to discover and bulk update power management devices on your local area network (LAN).</p>
                </MarkdownContent>
             </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
      
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">Got Questions? Ready to Get Started? WYN Technologies Can Help!</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                 <p>Reach out to the pros at WYN Technologies today, and let’s discuss your goals and needs regarding power management equipment, along with any other IT needs you may have. Not only does WYN deliver and install whatever CYBERPOWER equipment you may need; we actually provide a full slate of business technology solutions. It’s all in our name; WYN is here to provide Whatever You Need to take your business or enterprise to the next level! Call us today at <a href="tel:3368990555">(336) 899-0555</a>.</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>

    </Layout>
  )
}
export default BrandListPage
